import React, { useState, useEffect } from 'react';
import './Career.css';
import Header from '../../components/Header/Header';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Career() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    phoneNumber: '',
    profile: '',
    resume: null,
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
    phoneNumber: '',
    profile: '',
    resume: ''
  });

  const [submissionStatus, setSubmissionStatus] = useState('');

  // Validation functions
  const validateEmail = (email) => email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  const validatePhone = (phoneNumber) => phoneNumber.match(/^\d{10}$/);
  const validateResume = (resume) => {
    const allowedExtensions = ['pdf', 'doc', 'docx'];
    const fileExtension = resume?.name.split('.').pop().toLowerCase();
    const fileSizeInMB = resume?.size / 1024 / 1024; 
    return allowedExtensions.includes(fileExtension) && fileSizeInMB <= 1;
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });

    let errors = { ...formErrors };
    switch (name) {
      case 'email':
        errors.email = validateEmail(value) ? '' : 'Invalid Email ID.';
        break;
      case 'phoneNumber':
        errors.phoneNumber = validatePhone(value) ? '' : 'Phone number must be 10 digits.';
        break;
      case 'profile':
        errors.profile = value ? '' : 'Profile field cannot be empty.';
        break;
      case 'resume':
        errors.resume = validateResume(files[0]) ? '' : 'Resume must be a PDF, DOC, or DOCX file and less than 1 MB.';
        break;
      default:
        break;
    }

    setFormErrors(errors);
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!validateEmail(formData.email)) {
      isValid = false;
      errors.email = 'Invalid Email ID.';
    }

    if (!validatePhone(formData.phoneNumber)) {
      isValid = false;
      errors.phoneNumber = 'Phone number must be 10 digits.';
    }

    if (!formData.profile) {
      isValid = false;
      errors.profile = 'Profile field cannot be empty.';
    }

    if (!formData.resume || !validateResume(formData.resume)) {
      isValid = false;
      errors.resume = 'Resume must be a PDF, DOC, or DOCX file and less than 1 MB.';
    }

    setFormErrors(errors);
    return isValid;
  };

  // Check if user is logged in
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      // If no token, alert user to login and redirect to login page
      alert('Please login to submit your career form.');
      navigate('/Login');
    }
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Extract token from localStorage (just for authorization, not for email checking)
      const token = localStorage.getItem('token');

      if (token) {
        // Form submission data
        const formDataToSend = new FormData();
        formDataToSend.append('email', formData.email); // Allow different email input
        formDataToSend.append('phoneNumber', formData.phoneNumber);
        formDataToSend.append('profile', formData.profile);
        formDataToSend.append('resume', formData.resume);

        axios.post("http://13.235.115.160:5000/api/career", formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}` // Send token for authorization
          }
        })
        .then(response => {
          setSubmissionStatus('Form submitted successfully!');
        })
        .catch(error => {
          setSubmissionStatus('Error submitting the form.');
        });
      } else {
        // If no token, redirect to login
        alert('Your session has expired, please log in again.');
        navigate('/Login');
      }
    } else {
      console.log("Form validation failed.");
    }
  };

  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Header />
      <div className="career-header">
        <header>
          <h1>Career</h1>
          <hr className="HLine mx-auto mb-5" />
        </header>
      </div>
      <div className="careers-section">
        <h6>Fill out the form below for job opportunities</h6>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email ID:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {formErrors.email && <p className="error-message">{formErrors.email}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number:</label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              required
            />
            {formErrors.phoneNumber && <p className="error-message">{formErrors.phoneNumber}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="profile">Profile Applying for:</label>
            <input
              type="text"
              id="profile"
              name="profile"
              value={formData.profile}
              onChange={handleChange}
              required
            />
            {formErrors.profile && <p className="error-message">{formErrors.profile}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="resume">Upload Resume/CV:</label>
            <input
              type="file"
              id="resume"
              name="resume"
              accept=".pdf,.doc,.docx"
              onChange={handleChange}
              required
            />
            {formErrors.resume && <p className="error-message">{formErrors.resume}</p>}
          </div>
          <button type="submit">Submit</button>
          {submissionStatus && <div className="career-success">{submissionStatus}</div>}
        </form>
      </div>
    </>
  );
}

export default Career;



// import React, { useState, useEffect } from 'react';
// import './Career.css';
// import Header from '../../components/Header/Header';
// import axios from 'axios';
// import jwt_decode from 'jwt-decode'; // Import jwt-decode for decoding the token
// import { useNavigate } from 'react-router-dom';

// function Career() {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     email: '',
//     phoneNumber: '',
//     profile: '',
//     resume: null,
//   });

//   const [formErrors, setFormErrors] = useState({
//     email: '',
//     phoneNumber: '',
//     profile: '',
//     resume: ''
//   });

//   const [submissionStatus, setSubmissionStatus] = useState('');

//   // Validation functions
//   const validateEmail = (email) => email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
//   const validatePhone = (phoneNumber) => phoneNumber.match(/^\d{10}$/);
//   const validateResume = (resume) => {
//     const allowedExtensions = ['pdf', 'doc', 'docx'];
//     const fileExtension = resume?.name.split('.').pop().toLowerCase();
//     const fileSizeInMB = resume?.size / 1024 / 1024; 
//     return allowedExtensions.includes(fileExtension) && fileSizeInMB <= 1;
//   };

//   const handleChange = (e) => {
//     const { name, value, files } = e.target;
//     setFormData({
//       ...formData,
//       [name]: files ? files[0] : value,
//     });

//     let errors = { ...formErrors };
//     switch (name) {
//       case 'email':
//         errors.email = validateEmail(value) ? '' : 'Invalid Email ID.';
//         break;
//       case 'phoneNumber':
//         errors.phoneNumber = validatePhone(value) ? '' : 'Phone number must be 10 digits.';
//         break;
//       case 'profile':
//         errors.profile = value ? '' : 'Profile field cannot be empty.';
//         break;
//       case 'resume':
//         errors.resume = validateResume(files[0]) ? '' : 'Resume must be a PDF, DOC, or DOCX file and less than 1 MB.';
//         break;
//       default:
//         break;
//     }

//     setFormErrors(errors);
//   };

//   const validateForm = () => {
//     let errors = {};
//     let isValid = true;

//     if (!validateEmail(formData.email)) {
//       isValid = false;
//       errors.email = 'Invalid Email ID.';
//     }

//     if (!validatePhone(formData.phoneNumber)) {
//       isValid = false;
//       errors.phoneNumber = 'Phone number must be 10 digits.';
//     }

//     if (!formData.profile) {
//       isValid = false;
//       errors.profile = 'Profile field cannot be empty.';
//     }

//     if (!formData.resume || !validateResume(formData.resume)) {
//       isValid = false;
//       errors.resume = 'Resume must be a PDF, DOC, or DOCX file and less than 1 MB.';
//     }

//     setFormErrors(errors);
//     return isValid;
//   };

//   // Check if user is logged in
//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       // If no token, redirect to login
//       navigate('/Login');
//     }
//   }, [navigate]);

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (validateForm()) {
//       // Step 1: Extract token from localStorage (just for authorization, not for email checking)
//       const token = localStorage.getItem('token');

//       if (token) {
//         // Form submission data
//         const formDataToSend = new FormData();
//         formDataToSend.append('email', formData.email); // Allow different email input
//         formDataToSend.append('phoneNumber', formData.phoneNumber);
//         formDataToSend.append('profile', formData.profile);
//         formDataToSend.append('resume', formData.resume);

//         axios.post("http://13.235.115.160:5000/api/career", formDataToSend, {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//             'Authorization': `Bearer ${token}` // Send token for authorization
//           }
//         })
//         .then(response => {
//           setSubmissionStatus('Form submitted successfully!');
//         })
//         .catch(error => {
//           setSubmissionStatus('Error submitting the form.');
//         });
//       } else {
//         console.log('No token found. Redirecting to login...');
//         navigate('/Login');
//       }
//     } else {
//       console.log("Form validation failed.");
//     }
//   };

//   const handleKeyPress = (e) => {
//     const charCode = e.charCode;
//     if (charCode < 48 || charCode > 57) {
//       e.preventDefault();
//     }
//   };

//   return (
//     <>
//       <Header />
//       <div className="career-header">
//         <header>
//           <h1>Career</h1>
//           <hr className="HLine mx-auto mb-5" />
//         </header>
//       </div>
//       <div className="careers-section">
//         <h6>Fill out the form below for job opportunities</h6>
//         <form onSubmit={handleSubmit}>
//           <div className="form-group">
//             <label htmlFor="email">Email ID:</label>
//             <input
//               type="email"
//               id="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               required
//             />
//             {formErrors.email && <p className="error-message">{formErrors.email}</p>}
//           </div>
//           <div className="form-group">
//             <label htmlFor="phoneNumber">Phone Number:</label>
//             <input
//               type="tel"
//               id="phoneNumber"
//               name="phoneNumber"
//               value={formData.phoneNumber}
//               onChange={handleChange}
//               onKeyPress={handleKeyPress}
//               required
//             />
//             {formErrors.phoneNumber && <p className="error-message">{formErrors.phoneNumber}</p>}
//           </div>
//           <div className="form-group">
//             <label htmlFor="profile">Profile Applying for:</label>
//             <input
//               type="text"
//               id="profile"
//               name="profile"
//               value={formData.profile}
//               onChange={handleChange}
//               required
//             />
//             {formErrors.profile && <p className="error-message">{formErrors.profile}</p>}
//           </div>
//           <div className="form-group">
//             <label htmlFor="resume">Upload Resume/CV:</label>
//             <input
//               type="file"
//               id="resume"
//               name="resume"
//               accept=".pdf,.doc,.docx"
//               onChange={handleChange}
//               required
//             />
//             {formErrors.resume && <p className="error-message">{formErrors.resume}</p>}
//           </div>
//           <button type="submit">Submit</button>
//           {submissionStatus && <div className="career-success">{submissionStatus}</div>}
//         </form>
//       </div>
//     </>
//   );
// }

// export default Career;

// import React, { useState, useEffect } from 'react';
// import './Career.css';
// import Header from '../../components/Header/Header';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { jwtDecode } from 'jwt-decode';


// function Career() {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     email: '',
//     phoneNumber: '',
//     profile: '',
//     resume: null,
//   });

//   const [formErrors, setFormErrors] = useState({
//     email: '',
//     phoneNumber: '',
//     profile: '',
//     resume:''
//   });

//   const [submissionStatus, setSubmissionStatus] = useState('');

//   // Validation functions
//   const validateEmail = (email) => email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
//   const validatePhone = (phoneNumber) => phoneNumber.match(/^\d{10}$/);
//   const validateResume = (resume) => {
//     const allowedExtensions = ['pdf', 'doc', 'docx'];
//     const fileExtension = resume?.name.split('.').pop().toLowerCase();
//     const fileSizeInMB = resume?.size / 1024 / 1024; 
//     return allowedExtensions.includes(fileExtension) && fileSizeInMB <= 1;
//   };

//   const handleChange = (e) => {
//     const { name, value, files } = e.target;
//     setFormData({
//       ...formData,
//       [name]: files ? files[0] : value,
//     });

//     let errors = { ...formErrors };
//     switch (name) {
//       case 'email':
//         errors.email = validateEmail(value) ? '' : 'Invalid Email ID.';
//         break;
//       case 'phoneNumber':
//         errors.phoneNumber = validatePhone(value) ? '' : 'Phone number must be 10 digits.';
//         break;
//       case 'profile':
//         errors.profile = value ? '' : 'Profile field cannot be empty.';
//         break;
//       case 'resume':
//         errors.resume = validateResume(files[0]) ? '' : 'Resume must be a PDF, DOC, or DOCX file and less than 1 MB.';
//         break;
//       default:
//         break;
//     }

//     setFormErrors(errors);
//   };

//   const validateForm = () => {
//     let errors = {};
//     let isValid = true;

//     if (!validateEmail(formData.email)) {
//       isValid = false;
//       errors.email = 'Invalid Email ID.';
//     }

//     if (!validatePhone(formData.phoneNumber)) {
//       isValid = false;
//       errors.phoneNumber = 'Phone number must be 10 digits.';
//     }

//     if (!formData.profile) {
//       isValid = false;
//       errors.profile = 'Profile field cannot be empty.';
//     }

//     if (!formData.resume || !validateResume(formData.resume)) {
//       isValid = false;
//       errors.resume = 'Resume must be a PDF, DOC, or DOCX file and less than 1 MB.';
//     }

//     setFormErrors(errors);
//     return isValid;
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (validateForm()) {
//       // Step 1: Extract token from localStorage
//       const token = localStorage.getItem('token'); 

//       if (token) {
//         try {
//           // Step 2: Decode the token to get the user's email
//           const decoded = jwtDecode (token);
//           const emailFromToken = decoded.email;

//           // Step 3: Send request to backend to check if the email exists in the user table
//           axios.post("http://your-backend-url.com/api/check-email", { email: emailFromToken })
//           .then(response => {
//             if (response.data.exists) {
//               // Email exists, proceed with form submission

//               const formDataToSend = new FormData();
//               formDataToSend.append('email', formData.email);
//               formDataToSend.append('phoneNumber', formData.phoneNumber);
//               formDataToSend.append('profile', formData.profile);
//               formDataToSend.append('resume', formData.resume);

//               axios.post("http://13.235.115.160:5000/api/career", formDataToSend, {
//                 headers: {
//                   'Content-Type': 'multipart/form-data',
//                 }
//               })
//               .then(response => {
//                 setSubmissionStatus('Form submitted successfully!');
//               })
//               .catch(error => {
//                 setSubmissionStatus('Error submitting the form.');
//               });

//             } else {
//               // Email does not exist, navigate to registration page
//               navigate('/Signup');
//             }
//           })
//           .catch(error => {
//             console.error('Error checking email:', error);
//           });

//         } catch (error) {
//           console.error('Error decoding token:', error);
//         }
//       } else {
//         console.log('No token found. Redirecting to login...');
//         navigate('/Login');
//       }
//     } else {
//       console.log("Form validation failed.");
//     }
//   };

//   const handleKeyPress = (e) => {
//     const charCode = e.charCode;
//     if (charCode < 48 || charCode > 57) {
//       e.preventDefault();
//     }
//   };

//   return (
//     <>
//       <Header />
//       <div className="career-header">
//         <header>
//           <h1>Career</h1>
//           <hr className="HLine mx-auto mb-5" />
//         </header>
//       </div>
//       <div className="careers-section">
//         <h6>Fill out the form below for job opportunities</h6>
//         <form onSubmit={handleSubmit}>
//           <div className="form-group">
//             <label htmlFor="email">Email ID:</label>
//             <input
//               type="email"
//               id="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               required
//             />
//             {formErrors.email && <p className="error-message">{formErrors.email}</p>}
//           </div>
//           <div className="form-group">
//             <label htmlFor="phoneNumber">Phone Number:</label>
//             <input
//               type="tel"
//               id="phoneNumber"
//               name="phoneNumber"
//               value={formData.phoneNumber}
//               onChange={handleChange}
//               onKeyPress={handleKeyPress}
//               required
//             />
//             {formErrors.phoneNumber && <p className="error-message">{formErrors.phoneNumber}</p>}
//           </div>
//           <div className="form-group">
//             <label htmlFor="profile">Profile Applying for:</label>
//             <input
//               type="text"
//               id="profile"
//               name="profile"
//               value={formData.profile}
//               onChange={handleChange}
//               required
//             />
//             {formErrors.profile && <p className="error-message">{formErrors.profile}</p>}
//           </div>
//           <div className="form-group">
//             <label htmlFor="resume">Upload Resume/CV:</label>
//             <input
//               type="file"
//               id="resume"
//               name="resume"
//               accept=".pdf,.doc,.docx"
//               onChange={handleChange}
//               required
//             />
//             {formErrors.resume && <p className="error-message">{formErrors.resume}</p>}
//           </div>
//           <button type="submit">Submit</button>
//           {submissionStatus && <div className="career-success">{submissionStatus}</div>}
//         </form>
//       </div>
//     </>
//   );
// }

// export default Career;

// import React, { useState } from 'react';
// import './Career.css';
// import Header from '../../components/Header/Header';
// import axios from 'axios';

// function Career() {
//   const [formData, setFormData] = useState({
//     email: '',
//     phoneNumber: '',
//     profile: '',
//     resume: null,
//   });

//   const [formErrors, setFormErrors] = useState({
//     email: '',
//     phoneNumber: '',
//     profile: '',
//     resume:'' });

//   const [submissionStatus, setSubmissionStatus] = useState('');

//   const validateEmail = (email) => {
//     return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
//   };

//   const validatePhone = (phoneNumber) => {
//     return phoneNumber.match(/^\d{10}$/);
//   };

//   const validateResume = (resume) => {
//     const allowedExtensions = ['pdf', 'doc', 'docx'];
//     const fileExtension = resume?.name.split('.').pop().toLowerCase();
//     const fileSizeInMB = resume?.size / 1024 / 1024; 
//     return allowedExtensions.includes(fileExtension) && fileSizeInMB <= 1;
//   };

//   const handleChange = (e) => {
//     const { name, value, files } = e.target;
//     setFormData({
//       ...formData,
//       [name]: files ? files[0] : value,
//     });

//     // Validate individual field and clear errors if valid
//     let errors = { ...formErrors };

//     switch (name) {
//       case 'email':
//         errors.email = validateEmail(value) ? '' : 'Invalid Email ID.';
//         break;
//       case 'pphoneNumber':
//         errors.phoneNumber = validatePhone(value) ? '' : 'Phone number must be 10 digits.';
//         break;
//       case 'profile':
//         errors.profile = value ? '' : 'Profile field cannot be empty.';
//         break;
//       case 'resume':
//         errors.resume = validateResume(files[0]) ? '' : 'Resume must be a PDF, DOC, or DOCX file and less than 1 MB.';
//         break;
//       default:
//         break;
//     }

//     setFormErrors(errors);
//   };

//   const validateForm = () => {
//     let errors = {};
//     let isValid = true;

//     if (!validateEmail(formData.email)) {
//       isValid = false;
//       errors.email = 'Invalid Email ID.';
//     }

//     if (!validatePhone(formData.phoneNumber)) {
//       isValid = false;
//       errors.phoneNumber = 'Phone number must be 10 digits.';
//     }

//     if (!formData.profile) {
//       isValid = false;
//       errors.profile = 'Profile field cannot be empty.';
//     }

//     if (!formData.resume || !validateResume(formData.resume)) {
//       isValid = false;
//       errors.resume = 'Resume must be a PDF, DOC, or DOCX file and less than 1 MB.';
//     }

//     setFormErrors(errors);
//     return isValid;
//   };
//   const handleSubmit = (e) => {
//     e.preventDefault();
  
//     if (validateForm()) {
//       // Create a new FormData object
//       const formDataToSend = new FormData();
//       formDataToSend.append('email', formData.email);
//       formDataToSend.append('phoneNumber', formData.phoneNumber);
//       formDataToSend.append('profile', formData.profile);
  
//       if (formData.resume) {
//         formDataToSend.append('resume', formData.resume);
//       }
  
//       axios.post("http://13.235.115.160:5000/api/career", formDataToSend, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         }
//       })
//       .then(response => {
//         console.log('Form submitted successfully:', response);
//         setSubmissionStatus('Form submitted successfully!');
//       })
//       .catch(error => {
//         console.error('Error submitting form:', error.response?.data || error.message);
//         setSubmissionStatus('Error submitting the form.');
//       });
  
//       // Clear the form data after submission
//       setTimeout(() => {
//         setFormData({
//           email: '',
//           pphoneNumber: '',
//           profile: '',
//           resume: null,
//         });
//         setSubmissionStatus('');
//       }, 3000);
//     } else {
//       console.log("Form validation failed.");
//     }
//   };
  
  
//   /*const handleSubmit = (e) => {
//     e.preventDefault();

//     if (validateForm()) {
//       console.log('Form submitted:', formData);
//       setSubmissionStatus('Form submitted successfully!');

//       axios.post("http://13.235.115.160:5000/career", {
//         email: formData.email,
//         phoneNumber: formData.phoneNumber,
//         profile: formData.profile,
//         resume_: formData.resume,
//       })
//       .then(response => {
//         console.log(response);
//       })
//       .catch(error => {
//         console.log(error);
//       });

//       // Clear the form data after a short delay
//       setTimeout(() => {
//         setFormData({
//           email: '',
//           phoneNumber: '',
//           profile: '',
//           resume: null,
//         });
//         setSubmissionStatus('');
//       }, 3000); // Clear the form after 3 seconds
//     }
//   };*/

//   const handleKeyPress = (e) => {
//     const charCode = e.charCode;
//     if (charCode < 48 || charCode > 57) {
//       e.preventDefault();
//     }
//   };

//   return (
//     <>
//       <Header />
//       <div className="career-header">
//         <header>
//           <h1>Career</h1>
//           <hr className="HLine mx-auto mb-5" />
//         </header>
//       </div>
//       <div className="careers-section">
//         <h6>Fill out the form below for job opportunities</h6>
//         <form onSubmit={handleSubmit}>
//           <div className="form-group">
//             <label htmlFor="email">Email ID:</label>
//             <input
//               type="email"
//               id="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               required
//             />
//             {formErrors.email && <p className="error-message">{formErrors.email}</p>}
//           </div>
//           <div className="form-group">
//             <label htmlFor="phoneNumber">Phone Number:</label>
//             <input
//               type="tel"
//               id="phoneNumber"
//               name="phoneNumber"
//               value={formData.phoneNumber}
//               onChange={handleChange}
//               onKeyPress={handleKeyPress}
//               required
//             />
//             {formErrors.phoneNumber && <p className="error-message">{formErrors.phoneNumber}</p>}
//           </div>
//           <div className="form-group">
//             <label htmlFor="profile">Profile Applying for:</label>
//             <input
//               type="text"
//               id="profile"
//               name="profile"
//               value={formData.profile}
//               onChange={handleChange}
//               required
//             />
//             {formErrors.profile && <p className="error-message">{formErrors.profile}</p>}
//           </div>
//           <div className="form-group">
//             <label htmlFor="resume">Upload Resume/CV:</label>
//             <input
//               type="file"
//               id="resume"
//               name="resume"
//               accept=".pdf,.doc,.docx"
//               onChange={handleChange}
//               required
//             />
//             {formErrors.resume && <p className="error-message">{formErrors.resume}</p>}
//           </div>
//           <button type="submit">Submit</button>
//           {submissionStatus && <div className="career-success">{submissionStatus}</div>}
//         </form>
//       </div>
//     </>
//   );
// }

// export default Career;


// /*import React, { useState } from 'react';
// import './Career.css';
// import Header from '../../components/Header/Header';
// import axios from 'axios';
// function Career() {
//   const [formData, setFormData] = useState({
//     email: '',
//     phone: '',
//     profile: '',
//     resume: null,
//   });
//   const [errors, setErrors] = useState({});

//   const validateEmail = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(email);
//   };

//   const validatePhone = (phone) => {
//     const phoneRegex = /^\d{10}$/;
//     return phoneRegex.test(phone);
//   };

//   const validateResume = (resume) => {
//     const allowedExtensions = ['pdf', 'doc', 'docx'];
//     const fileExtension = resume?.name.split('.').pop().toLowerCase();
//     const fileSizeInMB = resume?.size / 1024 / 1024; 
//     return (
//       allowedExtensions.includes(fileExtension) &&
//       fileSizeInMB <= 1 
//     );
//   };

//   const handleChange = (e) => {
//     const { name, value, files } = e.target;
//     setFormData({
//       ...formData,
//       [name]: files ? files[0] : value,
//     });
//     setErrors({
//       ...errors,
//       [name]: '',
//     });
//   };
//   const handleKeyPress = (e) => {
//     const charCode = e.charCode;
//     if (charCode < 48 || charCode > 57) {
//       e.preventDefault();
//     }
//   };

//   const handleSubmit = (e) => {
    
//     let validationErrors = {};

//     if (!validateEmail(formData.email)) {
//       validationErrors.email = 'Invalid email address';
//     }

//     if (!validatePhone(formData.phone)) {
//       validationErrors.phone = 'Phone number must be 10 digits';
//     }

//     if (!formData.profile) {
//       validationErrors.profile = 'Profile field cannot be empty';
//     }

//     if (!formData.resume || !validateResume(formData.resume)) {
//       validationErrors.resume = 'Resume must be a PDF, DOC, or DOCX file and less than 1 MB';
//     }

//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return;
//     }

//     console.log(formData);
//     e.preventDefault();
//     const email=e.target.email.value;
//     const phone_number=e.target.phone.value;
//     const profile=e.target.profile.value;
//     const resume_filename=e.target.resume.value;
//     axios.post("",{
//       email,
//       phone_number,
//       profile,
//       resume_filename,
//     })
//     .then(response=>{
//       console.log(response);
//     })
//     .catch((error)=>{
//       console.log(error);
//     });
//   };

//   return (
//     <>
//       <Header />
//       <div className="career-header">
//         <header>
//           <h1>Career</h1>
//           <hr className="HLine mx-auto mb-5" />
//         </header>
//       </div>
//       <div className="careers-section">
//         <h6>Fill out the form below for job opportunities</h6>
//         <form onSubmit={handleSubmit}>
//           <div className="form-group">
//             <label htmlFor="email">Email ID:</label>
//             <input
//               type="email"
//               id="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               required
//             />
//             {errors.email && <p className="error-message">{errors.email}</p>}
//           </div>
//           <div className="form-group">
//             <label htmlFor="phone">Phone Number:</label>
//             <input
//               type="tel"
//               id="phone"
//               name="phone"
//               value={formData.phone}
//               onChange={handleChange}
//               onKeyPress={handleKeyPress}
//               required
//             />
//             {errors.phone && <p className="error-message">{errors.phone}</p>}
//           </div>
//           <div className="form-group">
//             <label htmlFor="profile">Profile Applying for:</label>
//             <input
//               type="text"
//               id="profile"
//               name="profile"
//               value={formData.profile}
//               onChange={handleChange}
//               required
//             />
//             {errors.profile && <p className="error-message">{errors.profile}</p>}
//           </div>
//           <div className="form-group">
//             <label htmlFor="resume">Upload Resume/CV:</label>
//             <input
//               type="file"
//               id="resume"
//               name="resume"
//               accept=".pdf,.doc,.docx"
//               onChange={handleChange}
//               required
//             />
//             {errors.resume && <p className="error-message">{errors.resume}</p>}
//           </div>
//           <button type="submit">Submit</button>
//         </form>
//       </div>
//     </>
//   );
// }

// export default Career;
// */
