// import { ServiceData } from './ServiceData.js';
// import Services from './Services.js';
// import './Services.css'
// export default function DisplayServices(){
//     return(
//         <>
//         <div>
//       <header className="header-service text-center">
//         <h2>Services</h2>
//       </header>
//       <div className='service1'>
//         <div className='container'>
//           {ServiceData.slice(0, 3).map((service, index) => (
//             <Services key={index} image={service.image} title={service.title} description={service.description} />
//           ))}
//         </div>
//       </div>
//       <div className='service2'>
//         <div className='container'>
//           {ServiceData.slice(3).map((service, index) => (
//             <Services key={index} image={service.image} title={service.title} description={service.description} />
//           ))}
//         </div>
//       </div>
//       </div>
//         </>
//     )
// }

import { ServiceData } from './ServiceData.js';
import Services from './Services.js';
import './Services.css'
export default function DisplayServices(){
    return(
        <>
        <div>
      <header className="header-service text-center">
        <h2>Services</h2>
      </header>
      <Services/>
      </div>
      </>
    );
  }