import '../Header/Header.css';
import { HashLink as Link } from 'react-router-hash-link';

export default function Header() {
  return (
 <>
    
    <nav className="navbar navbar-expand-lg navbar-light fixed-top">
      <div className="container-fluid">
        <div className='navbar-logo-toggle-icon'>
        <a className="navbar-brand fs-2 fw-bold " href="#home">Turtu</a>
        <button className="navbar-toggler" id='toggler-button' type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        </div>
        <div className="collapse navbar-collapse" id='navbarSupportedContent'>
          <div className="navbar-nav ms-auto mb-2 mb-lg-0">
            <div className="nav-item">
              <div className="nav-link active">
                <Link to='/#home' smooth className='navigation'>Home</Link>
              </div>
            </div>
            {/*
            <div className="nav-item">
              <div className="nav-link">
                <Link to='/AboutUs' className='navigation'>About</Link>
              </div>
            </div>
            <div className="nav-item">
              <div className="nav-link">
                <Link to='/ServicesPage' smooth className='navigation'>Services</Link>
              </div>
            </div>
            <div className="nav-item">
              <div className="nav-link">
                <Link to='/ContactPage' smooth className='navigation'>Contact</Link>
              </div>
            </div>*/}
            <div className="nav-item">
              <div className="nav-link">
                <Link to='/Career' smooth className='navigation'>Career</Link>
              </div>
            </div>
            <div className="nav-item">
              <div className="nav-link">
                <Link to='/Blogs' smooth className='navigation'>Blogs</Link>
              </div>
            </div>
            <div className="nav-item">
              <div className="nav-link">
                <Link to='/Signup' smooth className='navigation'>SignUp</Link>
              </div>
            </div>
            <div className="nav-item">
              <div className="nav-link">
                <Link to='/Login' smooth className='navigation'>Login</Link>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </nav>
    {/*<Routes>
    
      <Route path="/Login" element={<Login/>}/>
    </Routes>*/}


  </>
  );
}