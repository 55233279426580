import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import './Login.css';
import axios from 'axios';
import loginimg from '../../Images/login.jpg';


export default function Login() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    let errors = { ...formErrors };

    switch (name) {
      case 'email':
        errors.email = value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
          ? ''
          : 'Invalid Email ID.';
        break;
      case 'password':
        errors.password = value.length >= 6 && value.length <= 20
          ? ''
          : 'Password should be 6 to 20 characters long.';
        break;
      default:
        break;
    }

    setFormErrors(errors);
  };

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!formData.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
      isValid = false;
      errors.email = 'Invalid Email ID.';
    }

    if (formData.password.length < 6 || formData.password.length > 20) {
      isValid = false;
      errors.password = 'Password should be 6 to 20 characters long.';
    }

    setFormErrors(errors);
    return isValid;
  };
  useEffect(() => {
    // Check if token exists in localStorage
    const token = localStorage.getItem('authToken');
    if (token) {
        // Token exists, redirect to home page
        navigate('/');
    }
}, [navigate]);
  // const generateToken = (email, password) => {
  //   // Combine email and password and hash them
  //   const combined = `${email}:${password}`;
  //   const token = CryptoJS.SHA256(combined).toString(CryptoJS.enc.Hex); // Generate a hash
  //   return token;
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
        axios.post("http://13.235.115.160:5000/login", {
            emailOrPhoneNumber: formData.emailOrPhoneNumber,
            password: formData.password,
        })
        .then(response => {
            if (response.data.token) {
                // Store JWT token in localStorage
                localStorage.setItem('authToken', response.data.token);
                // Redirect to home or dashboard
                window.location.href = '/';
            } else {
                setFormErrors({
                    ...formErrors,
                    password: response.data.message || 'Invalid email or password.'
                });
            }
        })
        .catch(error => {
            console.error('Login error:', error);
            setFormErrors({
                ...formErrors,
                password: 'An error occurred. Please try again.'
            });
        });
    }
};

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (validate()) {
  //     axios.post("http://13.235.115.160:5000/api/login", {
  //       email: formData.email,
  //       password: formData.password,
  //     }, {
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     })
  //       .then(response => {
  //         if (response.data.status === 'success') {
  //           console.log('Login successful:', response.data);

  //           // Generate token based on email and password
  //           const token = generateToken(formData.email, formData.password);

  //           // Store login state and the generated token in local storage
  //           localStorage.setItem('isLoggedIn', true);
  //           localStorage.setItem('authToken', token); // Store the generated token

  //           navigate('/');
  //         } else {
  //           setFormErrors({
  //             ...formErrors,
  //             password: response.data.message || 'Invalid email or password.'
  //           });
  //         }
  //       })
  //       .catch(error => {
  //         console.error('Login error:', error);
  //         setFormErrors({
  //           ...formErrors,
  //           password: 'An error occurred. Please try again.'
  //         });
  //       });
  //   }
  // };

  return (
    <section className='login-section-form'>
    <div id="Login" className="login">
      <div className="login-container">
        {/* Left side - Image */}
        <div className="login-img">
          <img src={loginimg} alt='login' />
        </div>
  
        {/* Right side - Login form */}
        <div className="login-section">
          <div className="login-header">
            <h2>Login</h2>
          </div>
          <form className="form-field" onSubmit={handleSubmit}>
            <div className="input-controls">
              <label htmlFor="email">Email ID</label>
              <input
                type="text"
                name="email"
                placeholder="Enter Email ID"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {formErrors.email && (
                <div className="login-error">{formErrors.email}</div>
              )}
            </div>
            <div className="input-controls">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              {formErrors.password && (
                <div className="login-error">{formErrors.password}</div>
              )}
            </div>
            <div className="input-controls">
              <button type="submit" className='login-btn'>Login</button>
            </div>
            <div className="forgot-text">
              <Link to={`/forgotpassword`}>
                <span>Forgot Password?</span>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  
  );
}


// import React, { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import './Login.css';
// import axios from 'axios';
// import loginimg from '../../Images/login.jpg';

// export default function Login() {
//   const navigate = useNavigate();

//   const [formData, setFormData] = useState({
//     email: '',
//     password: '',
//   });

//   const [formErrors, setFormErrors] = useState({
//     email: '',
//     password: '',
//   });

//   const [isSubmitting, setIsSubmitting] = useState(false); // For disabling the button during submission

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });

//     let errors = { ...formErrors };

//     switch (name) {
//       case 'email':
//         errors.email = value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
//           ? ''
//           : 'Invalid Email ID.';
//         break;
//       case 'password':
//         errors.password = value.length >= 6 && value.length <= 20
//           ? ''
//           : 'Password should be 6 to 20 characters long.';
//         break;
//       default:
//         break;
//     }

//     setFormErrors(errors);
//   };

//   const validate = () => {
//     let errors = {};
//     let isValid = true;

//     if (!formData.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
//       isValid = false;
//       errors.email = 'Invalid Email ID.';
//     }

//     if (formData.password.length < 6 || formData.password.length > 20) {
//       isValid = false;
//       errors.password = 'Password should be 6 to 20 characters long.';
//     }

//     setFormErrors(errors);
//     return isValid;
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (validate()) {
//       setIsSubmitting(true); // Disable button during submission
//       axios.post("http://13.235.115.160:5000/api/login", {
//         email: formData.email,
//         password: formData.password,
//       }, {
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       })
//         .then(response => {
//           if (response.data.status === 'success') {
//             console.log('Login successful:', response.data);

//             // Store the token returned by the backend in localStorage
//             localStorage.setItem('isLoggedIn', true);
//             localStorage.setItem('authToken', response.data.token); // Store the backend-generated token

//             navigate('/');
//           } else {
//             setFormErrors({
//               ...formErrors,
//               password: response.data.message || 'Invalid email or password.'
//             });
//           }
//         })
//         .catch(error => {
//           console.error('Login error:', error);
//           setFormErrors({
//             ...formErrors,
//             password: 'An error occurred. Please try again.'
//           });
//         })
//         .finally(() => {
//           setIsSubmitting(false); // Enable the button again after submission
//         });
//     }
//   };

//   return (
//     <section className='login-section-form'>
//       <div id="Login" className="login">
//         <div className="login-container">
//           {/* Left side - Image */}
//           <div className="login-img">
//             <img src={loginimg} alt='login' />
//           </div>

//           {/* Right side - Login form */}
//           <div className="login-section">
//             <div className="login-header">
//               <h2>Login</h2>
//             </div>
//             <form className="form-field" onSubmit={handleSubmit}>
//               <div className="input-controls">
//                 <label htmlFor="email">Email ID</label>
//                 <input
//                   type="text"
//                   name="email"
//                   placeholder="Enter Email ID"
//                   value={formData.email}
//                   onChange={handleChange}
//                   required
//                 />
//                 {formErrors.email && (
//                   <div className="login-error">{formErrors.email}</div>
//                 )}
//               </div>
//               <div className="input-controls">
//                 <label htmlFor="password">Password</label>
//                 <input
//                   type="password"
//                   name="password"
//                   placeholder="Password"
//                   value={formData.password}
//                   onChange={handleChange}
//                   required
//                 />
//                 {formErrors.password && (
//                   <div className="login-error">{formErrors.password}</div>
//                 )}
//               </div>
//               <div className="input-controls">
//                 <button type="submit" className='login-btn' disabled={isSubmitting}>
//                   {isSubmitting ? 'Logging in...' : 'Login'}
//                 </button>
//               </div>
//               <div className="forgot-text">
//                 <Link to={`/forgotpassword`}>
//                   <span>Forgot Password?</span>
//                 </Link>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// import React, { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import './Login.css';
// import axios from 'axios';

// export default function Login() {
//   const navigate = useNavigate();

//   const [formData, setFormData] = useState({
//     email: '',
//     password: '',
//   });

//   const [formErrors, setFormErrors] = useState({
//     email: '',
//     password: '',
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });

//     let errors = { ...formErrors };

//     switch (name) {
//       case 'email':
//         errors.email = value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
//           ? ''
//           : 'Invalid Email ID.';
//         break;
//       case 'password':
//         errors.password = value.length >= 6 && value.length <= 20
//           ? ''
//           : 'Password should be 6 to 20 characters long.';
//         break;
//       default:
//         break;
//     }

//     setFormErrors(errors);
//   };

//   const validate = () => {
//     let errors = {};
//     let isValid = true;

//     if (!formData.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
//       isValid = false;
//       errors.email = 'Invalid Email ID.';
//     }

//     if (formData.password.length < 6 || formData.password.length > 20) {
//       isValid = false;
//       errors.password = 'Password should be 6 to 20 characters long.';
//     }

//     setFormErrors(errors);
//     return isValid;
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (validate()) {
//       axios.post("http://13.235.115.160:5000/api/login", {
//         email: formData.email,
//         password: formData.password,
//       }, {
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       })
//       .then(response => {
//         if (response.data.status === 'success') {
//           console.log('Login successful:', response.data);
//           // Store login state in local storage
//           localStorage.setItem('isLoggedIn', true);
//           navigate('/');
//         } else {
//           setFormErrors({
//             ...formErrors,
//             password: response.data.message || 'Invalid email or password.'
//           });
//         }
//       })
//       .catch(error => {
//         console.error('Login error:', error);
//         setFormErrors({
//           ...formErrors,
//           password: 'An error occurred. Please try again.'
//         });
//       });
//     }
//   };

//   return (
//     <div id="Login" className="login">
//       <div className="login-header">
//         <h2>Login</h2>
//       </div>
//       <div>
//         <div className="login-section">
//           <form className="form-field" onSubmit={handleSubmit}>
//             <div className="input-controls">
//               <label htmlFor="email">Email ID</label>
//               <input
//                 type="text"
//                 name="email"
//                 placeholder="Enter Email ID"
//                 value={formData.email}
//                 onChange={handleChange}
//                 required
//               />
//               {formErrors.email && (
//                 <div className="login-error">{formErrors.email}</div>
//               )}
//             </div>
//             <div className="input-controls">
//               <label htmlFor="password">Password</label>
//               <input
//                 type="password"
//                 name="password"
//                 placeholder="Password"
//                 value={formData.password}
//                 onChange={handleChange}
//                 required
//               />
//               {formErrors.password && (
//                 <div className="login-error">{formErrors.password}</div>
//               )}
//             </div>
//             <div className="input-controls">
//               <button type="submit" className='login-btn'>Login</button>
//             </div>
//             <div className="forgot-text">
//               <Link to={`/forgotpassword`} >
//                 <span>Forgot Password?</span>
//               </Link>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// }
