import About from "../components/About/About"
import Header from "../components/Header/Header"
import '../components/About/About.css';
export default function AboutUs(){
    return(
        <>
        <Header/>
        <div className="about-margin">
        <About/>
        </div>
        </>
        
    )
}
