import Header from "../components/Header/Header"
import DisplayServices from "../components/Services/DisplayServices.js"
import '../components/Services/Services.css'
export default function ServicePage(){
    return(
        <>
        <Header/>
        <div className="service-margin">
        <DisplayServices/>
        </div>
        
        </>
        
    )
}
