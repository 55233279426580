import Contact from "../components/Contact/Contact"
import Header from "../components/Header/Header"
import '../components/Contact/Contact.css';
export default function ContactPage(){
    return(
        <>
        <Header/>
        <div className="contact-margin">
        <Contact/>
        </div>
        </>
        
    )
}
