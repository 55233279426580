import { useState, useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './Contact.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import contactImage from '../../Images/Contact1.png';

export default function Form() {
    const [isAuthenticated, setIsAuthenticated] = useState(false); // State to track if the user is logged in
    const navigate = useNavigate(); // For redirection

    useEffect(() => {
        Aos.init({ duration: '1000' });
        
        // Check if the user is authenticated
        const token = localStorage.getItem('authToken'); 
        if (token) {
            try {
                // Attempt to decode token to check if it's valid
                jwtDecode(token);
                setIsAuthenticated(true);
            } catch (error) {
                console.error("Invalid token", error);
                setIsAuthenticated(false);
            }
        } else {
            setIsAuthenticated(false);
        }
    }, []);

    const initialValues = { username: "", email: "", phone_number: "", queries: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Perform validation
        const errors = validate(formValues); 
        setFormErrors(errors);
        setIsSubmit(true);

        if (Object.keys(errors).length === 0) {
            try {
                const formData = new FormData();
                formData.append('username', formValues.username);
                formData.append('email', formValues.email);
                formData.append('phone_number', formValues.phone_number);
                formData.append('queries', formValues.queries);

                // Check if the user is authenticated (ignore the email they submit)
                const token = localStorage.getItem('authToken');
                let headers = {};
                if (token) {
                    headers = {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`, // Pass token in headers
                    };
                } else {
                    headers = {
                        'Content-Type': 'multipart/form-data',
                    };
                }

                // Submit the form, regardless of the logged-in status
                await axios.post("http://13.235.115.160:5000/api/contact", formData, { headers });

                setFormValues(initialValues); // Clear form fields
                setIsSuccess(true); // Set success state to true
                setTimeout(() => setIsSuccess(false), 5000); // Hide success message after 5 seconds
            } catch (error) {
                console.error("There was an error submitting the form!", error);
                alert("An error occurred. Please try again.");
            }
        }
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log(formValues);
        }
    }, [formErrors, formValues, isSubmit]);

    const validate = (values) => {
        const errors = {};
        if (!values.username) {
            errors.username = "Username is required";
        }
        if (values.username.length < 2) {
            errors.username = "Username must be more than 2 characters";
        }
        if (!values.email) {
            errors.email = "Email is required";
        }
        if (!values.phone_number) {
            errors.phone_number = "Phone number is required";
        } else if (values.phone_number.length < 10) {
            errors.phone_number = "Phone number is not valid";
        }
        if (!values.queries) {
            errors.queries = "Query message is required";
        }
        return errors;
    };

    const handleKeyPress = (e) => {
        const charCode = e.charCode;
        if (charCode < 48 || charCode > 57) {
            e.preventDefault();
        }
    };

    return (
        <section className="contact" id="contact">
            <div className="heading text-center">
                <h3>Contact Us</h3>
                <p>Leave us a message</p>
            </div>
            <div className="container">
                <div className="contact-image">
                    <img src={contactImage} alt="contact" />
                </div>
                <div className="contact-form">
                    <div className="form py-4">
                        <form onSubmit={handleSubmit}>
                            <div className="input-control">
                                <label htmlFor="fname">User Name</label>
                                <input
                                    type="text"
                                    name="username"
                                    id="username"
                                    placeholder="Username"
                                    value={formValues.username}
                                    onChange={handleChange}
                                />
                            </div>
                            <p>{formErrors.username}</p>
                            <div className="input-control">
                                <label htmlFor="fname">Email Address</label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Email Address"
                                    value={formValues.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <p>{formErrors.email}</p>
                            <div className="input-control">
                                <label htmlFor="fname">Phone Number</label>
                                <input
                                    type="text"
                                    name="phone_number"
                                    id="phone_number"
                                    placeholder="Phone Number"
                                    value={formValues.phone_number}
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                            </div>
                            <p>{formErrors.phone_number}</p>
                            <div className="input-control">
                                <label htmlFor="subject">Query</label>
                                <textarea
                                    id="queries"
                                    name="queries"
                                    placeholder="Write something.."
                                    value={formValues.queries}
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                            <p>{formErrors.queries}</p>
                            <div className="input-control">
                                <button type="submit">Submit</button>
                            </div>
                            {isSuccess && (
                                <div className="message-success">Message sent successfully!</div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

// import { useState, useEffect } from 'react';
// import Aos from 'aos';
// import 'aos/dist/aos.css';
// import './Contact.css';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { jwtDecode } from 'jwt-decode';
// import contactImage from '../../Images/Conatct.png';

// export default function Form() {
//     const [isAuthenticated, setIsAuthenticated] = useState(false); // State to track if the user is logged in
//     const navigate = useNavigate(); // For redirection

//     useEffect(() => {
//         Aos.init({ duration: '1000' });
        
//         // Check if the user is authenticated
//         const token = localStorage.getItem('authToken'); 
//         if (token) {
//             setIsAuthenticated(true);
//         } else {
//             setIsAuthenticated(false);
//         }
//     }, []);

//     const initialValues = { username: "", email: "", phone_number: "", queries: "" };
//     const [formValues, setFormValues] = useState(initialValues);
//     const [formErrors, setFormErrors] = useState({});
//     const [isSubmit, setIsSubmit] = useState(false);
//     const [isSuccess, setIsSuccess] = useState(false);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormValues({ ...formValues, [name]: value });
//     };
//     const handleSubmit = async (e) => {
//         e.preventDefault();
        
//         // Check if user is authenticated
//         const token = localStorage.getItem('authToken');
//         if (!token) {
//             alert("You must be logged in to submit a query.");
//             navigate('/login'); // Redirect to login if user is not logged in
//             return;
//         }
    
//         try {
//             // Decode token to get email
//             const decodedToken = jwtDecode(token);
//             const userEmail = decodedToken.email; // Adjust based on your token structure
            
//             // Verify email with backend
//             const response = await axios.post("http://13.235.115.160:5000/api/verify_email", {
//                 email: userEmail
//             });
    
//             if (response.data.exists) {
//                 // Email exists in the database, proceed with form submission
//                 const errors = validate(formValues); // Perform validation
//                 setFormErrors(errors);
//                 setIsSubmit(true);
    
//                 if (Object.keys(errors).length === 0) {
//                     const formData = new FormData();
//                     formData.append('username', formValues.username);
//                     formData.append('email', formValues.email);
//                     formData.append('phone_number', formValues.phone_number);
//                     formData.append('queries', formValues.queries);
                    
//                     axios.post("http://13.235.115.160:5000/api/contact", formData, {
//                         headers: {
//                             'Content-Type': 'multipart/form-data',
//                             'Authorization': `Bearer ${token}` // Pass token in headers
//                         }
//                     })
//                     .then(response => {
//                         setFormValues(initialValues); // Clear form fields
//                         setIsSuccess(true); // Set success state to true
//                         setTimeout(() => setIsSuccess(false), 5000); // Hide success message after 5 seconds
//                     })
//                     .catch(error => {
//                         console.error("There was an error submitting the form!", error);
//                     });
//                 }
//             } else {
//                 // Email does not exist in the database, redirect to registration page
//                 alert("Your email is not registered. Please register first.");
//                 navigate('/register');
//             }
//         } catch (error) {
//             console.error("Error verifying email or decoding token", error);
//             alert("An error occurred. Please try again.");
//         }
//     };
//     // const handleSubmit = (e) => {
//     //     e.preventDefault();
//     //     if (!isAuthenticated) {
//     //         alert("You must be logged in to submit a query.");
//     //         navigate('/login'); // Redirect to login if user is not logged in
//     //         return;
//     //     }

//     //     const errors = validate(formValues); // Perform validation
//     //     setFormErrors(errors);
//     //     setIsSubmit(true);

//     //     if (Object.keys(errors).length === 0) {
//     //         const formData = new FormData();
//     //         formData.append('username', formValues.username);
//     //         formData.append('email', formValues.email);
//     //         formData.append('phone_number', formValues.phone_number);
//     //         formData.append('queries', formValues.queries);
            
//     //         axios.post("http://13.235.115.160:5000/api/contact", formData, {
//     //             headers: {
//     //                 'Content-Type': 'multipart/form-data',
//     //                 'Authorization': `Bearer ${localStorage.getItem('authToken')}` // Pass token in headers
//     //             }
//     //         })
//     //         .then(response => {
//     //             setFormValues(initialValues); // Clear form fields
//     //             setIsSuccess(true); // Set success state to true
//     //             setTimeout(() => setIsSuccess(false), 5000); // Hide success message after 5 seconds
//     //         })
//     //         .catch(error => {
//     //             console.error("There was an error submitting the form!", error);
//     //         });
//     //     }
//     // };

//     useEffect(() => {
//         if (Object.keys(formErrors).length === 0 && isSubmit) {
//             console.log(formValues);
//         }
//     }, [formErrors, formValues, isSubmit]);

//     const validate = (values) => {
//         const errors = {};
//         if (!values.username) {
//             errors.username = "Username is required";
//         }
//         if (values.username.length < 2) {
//             errors.username = "Username must be more than 2 characters";
//         }
//         if (!values.email) {
//             errors.email = "Email is required";
//         }
//         if (!values.phone_number) {
//             errors.phone_number = "Phone number is required";
//         } else if (values.phone_number.length < 10) {
//             errors.phone_number = "Phone number is not valid";
//         }
//         if (!values.queries) {
//             errors.queries = "Query message is required";
//         }
//         return errors;
//     };

//     const handleKeyPress = (e) => {
//         const charCode = e.charCode;
//         if (charCode < 48 || charCode > 57) {
//             e.preventDefault();
//         }
//     };

//     return (
//         <section className="contact" id="contact">
//             <div className="heading text-center">
//                 <h3>Contact Us</h3>
//                 <p>Leave us a message</p>
//             </div>
//             <div className="container">
//                 <div className="contact-image">
//                     <img src={contactImage} alt="contact" />
//                 </div>
//                 <div className="contact-form">
//                     <div className="form py-4">
//                         <form onSubmit={handleSubmit}>
//                             <div className="input-control">
//                                 <label htmlFor="fname">User Name</label>
//                                 <input
//                                     type="text"
//                                     name="username"
//                                     id="username"
//                                     placeholder="Username"
//                                     value={formValues.username}
//                                     onChange={handleChange}
//                                 />
//                             </div>
//                             <p>{formErrors.username}</p>
//                             <div className="input-control">
//                                 <label htmlFor="fname">Email Address</label>
//                                 <input
//                                     type="email"
//                                     name="email"
//                                     id="email"
//                                     placeholder="Email Address"
//                                     value={formValues.email}
//                                     onChange={handleChange}
//                                 />
//                             </div>
//                             <p>{formErrors.email}</p>
//                             <div className="input-control">
//                                 <label htmlFor="fname">Phone Number</label>
//                                 <input
//                                     type="text"
//                                     name="phone_number"
//                                     id="phone_number"
//                                     placeholder="Phone Number"
//                                     value={formValues.phone_number}
//                                     onChange={handleChange}
//                                     onKeyPress={handleKeyPress}
//                                 />
//                             </div>
//                             <p>{formErrors.phone_number}</p>
//                             <div className="input-control">
//                                 <label htmlFor="subject">Query</label>
//                                 <textarea
//                                     id="queries"
//                                     name="queries"
//                                     placeholder="Write something.."
//                                     value={formValues.queries}
//                                     onChange={handleChange}
//                                 ></textarea>
//                             </div>
//                             <p>{formErrors.queries}</p>
//                             <div className="input-control">
//                                 <button type="submit">Submit</button>
//                             </div>
//                             {isSuccess && (
//                                 <div className="message-success">Message sent successfully!</div>
//                             )}
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </section>
//     );
// }
