// import React from "react";
// import "./Services.css";
// import deliveryImage from '../../Images/delivery-services.png';

// const servicesData = [
//   {
//     title: "Pick and Drop",
//     description: "Have you left or forgotten anything at home / office / anywhere? Get it picked and dropped at your doorstep.",
//     buttonText: "Read More",
//     imgSrc: deliveryImage, 
//     reverse: true,
//   },
//   {
//     title: "Courier Services",
//     description: "Need something delivered quickly and securely? Our courier services have you covered.",
//     buttonText: "Read More",
//     imgSrc: deliveryImage,
//     reverse: false,
//   },
//   {
//     title: "Food Delivery",
//     description: "Need something delivered quickly and securely? Our courier services have you covered.",
//     buttonText: "Read More",
//     imgSrc: deliveryImage,
//     reverse: true,
//   },
//   {
//     title: "Cake Delivery",
//     description: "Need something delivered quickly and securely? Our courier services have you covered.",
//     buttonText: "Read More",
//     imgSrc: deliveryImage,
//     reverse: false,
//   },
//   {
//     title: "Gifts and Flower",
//     description: "Need something delivered quickly and securely? Our courier services have you covered.",
//     buttonText: "Read More",
//     imgSrc: deliveryImage,
//     reverse: true,
//   },
//   {
//     title: "Medicine Delivery",
//     description: "Need something delivered quickly and securely? Our courier services have you covered.",
//     buttonText: "Read More",
//     imgSrc: deliveryImage,
//     reverse: false,
//   },
// ];

// const ServiceCard = ({ title, description, buttonText, imgSrc, reverse }) => {
//   return (
//     <div className={`service-card ${reverse ? "reverse" : ""}`}>
//       <div className="service-content">
//         <h3>{title}</h3>
//         <p>{description}</p>
//         <button>{buttonText}</button>
//       </div>
//       <div className="service-image">
//         <img src={imgSrc} alt={title} />
//       </div>
//     </div>
//   );
// };

// const Services = () => {
//   return (
//     <div className="services-container">
//       <h2>Services</h2>
//       {servicesData.map((service, index) => (
//         <ServiceCard key={index} {...service} />
//       ))}
//     </div>
//   );
// };

// export default Services;
import React from 'react';
import { Link } from 'react-router-dom';
import deliveryImage from '../../Images/delivery-services.png';
import CourierServicesImg from '../../Images/Courier_Delivery.png';
import FoodDeliveryImg from '../../Images/Food_Delivery.png';
import CakeDeliveryImage from '../../Images/Cake_delivery.png';
import GiftDeliveryImage from '../../Images/Frame 4.png';
import PickanddropImage from '../../Images/Document _delivery.png';
import MedicineDeliveryImage from '../../Images/Medicine_Delivery.png';
const services = [
  {
    id: 1,
    image:  CourierServicesImg,
    heading: 'Courier Services',
    text: 'Have you left or forgotten anything at home / office / anywhere? Get it picked and dropped at your doorstep.',
    link: '/service1'
  },
  {
    id: 2,
    image:  FoodDeliveryImg,
    heading: 'Food Delivery',
    text: 'Have you left or forgotten anything at home / office / anywhere? Get it picked and dropped at your doorstep.',
    link: '/service2'
  },
  {
    id: 3,
    image:  PickanddropImage,
    heading: 'Pick and Drop',
    text: 'Have you left or forgotten anything at home / office / anywhere? Get it picked and dropped at your doorstep.',
    link: '/service1'
  },
  {
    id: 4,
    image:  CakeDeliveryImage,
    heading: 'Cake Delivery',
    text: 'Have you left or forgotten anything at home / office / anywhere? Get it picked and dropped at your doorstep.',
    link: '/service1'
  },
  {
    id: 5,
    image:  GiftDeliveryImage,
    heading: 'Gifts and Flower',
    text: 'Have you left or forgotten anything at home / office / anywhere? Get it picked and dropped at your doorstep.',
    link: '/service1'
  },
  {
    id: 6,
    image: MedicineDeliveryImage,
    heading: 'Medicine Delivery',
    text: 'Have you left or forgotten anything at home / office / anywhere? Get it picked and dropped at your doorstep.',
    link: '/service1'
  },
];
const ServicePage = () => {
    return (
      <div className="container service-page">
        {/* Row to hold 2 services per row with a gap */}
        <div className="row">
          {services.map((service, index) => (
            <div key={service.id} className="col-md-6 g-5">
              <div className="row service-box">
                {/* Left Column: Image */}
                <div className="col-md-5">
                  <img src={service.image} className="img-fluid service-img" alt={service.heading} />
                </div>
                {/* Right Column: Content */}
                <div className="col-md-7 d-flex flex-column justify-content-center">
                  <h5 className="service-heading">{service.heading}</h5>
                  <p className="service-text">{service.text}</p>
                  <Link to={service.link} className="btn btn-primary service-btn">Read More</Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default ServicePage;
