import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header.js';
import Carousel from './components/Carousel/Carousel.js';
import About from './components/About/About.js';
import Counter from './components/Counter/Counter.js';

import Form from './components/Contact/Contact.js';
import Footer from './components/Footer/Footer.js';

import { CounterData } from './components/Counter/CounterData.js';
import './components/Testimonial/Testimonial.css';
import TestimonialCarousel from './components/Testimonial/Testimonial.js';
import Blogs from './Pages/Blogs/Blogs.js';
import RootLayout from './Pages/RootLayout.js';
import BlogDetailArticle from './Pages/Blogs/BlogDetailArticle.js';
import BlogsDetailsPage from './Pages/Blogs/BlogsDetailsPage.js';
import Career from './Pages/Career/Career.js';
import Login from './Pages/Login/Login.js';
//import ResetPasswordEmail from './Pages/ResetPasswordEmail/ResetPasswordEmail.js'
import SignUp from './Pages/SignUp/SignUp.js'
import AboutUs from './Pages/AboutUs.js';
import './App.css';
import ServicePage from './Pages/ServicesPage.js';
import ContactPage from './Pages/ContactPage.js';
import HowItWorks from './components/HowItWorks/HowItWorks.js';
import DisplayServices from './components/Services/DisplayServices.js';
import OTPVerification from './components/OTPVerification/OTPVerification.js';
import ForgotPassword from './components/Password/ForgotPassword.js';
import ResetPassword from './components/Password/ResetPassword.js';
import Services from './components/Services/Services.js';
import CounterDisplay from './components/Counter/CounterDisplay.js';

function Home() {

  const [showSignUp, setShowSignUp] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

 /* useEffect(() => {
    // Check if the user is logged in
    const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedIn);

    // If not logged in, show the sign-up form after a delay
    if (!loggedIn) {
      const timer = setTimeout(() => {
        setShowSignUp(true);
      }, 5000); // Show SignUp form after 5 seconds

      return () => clearTimeout(timer);
    }
  }, []);    */
  return (
    <div className="home-page">
      {/* Home Page Content */}
      <Header />
      <Carousel />
      <About />
     <CounterDisplay/>
      <HowItWorks/>
     <DisplayServices/>
      <div className="App">
        <header className="header-testimonial text-center my-5">
          <h2>What customer say</h2>
        </header>
        <TestimonialCarousel />
      </div>
      <Form />
      <Footer />

      {/* Conditionally render SignUp form */}
      {showSignUp && (
        <div className="signup-overlay">
          <SignUp />
        </div>
      )}

      {/* Optional styling to blur or darken background */}
      <style jsx="true">{`
        .home-page {
          position: relative;
          z-index: 1;
        }
        .signup-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.5); /* Slight dark overlay */
        }
  }
      `}</style>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home/>}/>
        <Route path="/Blogs" element={<Blogs />} />
        <Route path='/Career' element={<Career/>}/>
        <Route path='/' element={<RootLayout/>}/>
        <Route path='/BlogDetail' element={<BlogDetailArticle/>}/>
        <Route path="/" element={<BlogsDetailsPage/>} />
        <Route path="/Blog/:title" element={<BlogDetailArticle/>} />
        <Route path='/Login' element={<Login/>}/>
        {/*<Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/resetpassword/?token=:token" component={ResetPassword} />
        <Route path='/Email' element={<ResetPasswordEmail/>}/>*/}
        <Route path="/Signup" element={<SignUp/>} />
        <Route path='/AboutUs' element={<AboutUs/>}/>
        <Route path='/ServicesPage' element={<ServicePage/>}/>
        <Route path='/ContactPage' element={<ContactPage/>}/>
        <Route path='/OTPVerification' element={<OTPVerification/>}/>
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
