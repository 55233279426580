// OTPVerification.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Updated import


function OTPVerification({ email }) {
  const [otp, setOtp] = useState('');
  const [status, setStatus] = useState('');
  const navigate = useNavigate(); // Updated hook

  const handleOtpVerification = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        'http://13.235.115.160:5000/verify-otp',
        { email, otp },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.success) {
        setStatus('Email verified successfully!');
        setTimeout(() => {
          navigate('/home'); // Navigate to home page after verification
        }, 3000); // Redirect after 3 seconds
      } else {
        setStatus('Invalid OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setStatus('Failed to verify OTP. Please try again.');
    }
  };

  return (
    <div className="otp-verification-container">
      <form className="otp-form" onSubmit={handleOtpVerification}>
        <h2 className="otp-title">Verify OTP</h2>
        <div className="otp-form-group">
          <div className="otp-label">
            <label>Enter OTP</label>
          </div>
          <input
            className="otp-input"
            type="text"
            name="otp"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
          />
        </div>
        <button className="otp-button" type="submit">Verify OTP</button>
        {status && <div className="otp-status">{status}</div>}
      </form>
    </div>
  );
}

export default OTPVerification;
